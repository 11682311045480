import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class TreeElementDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  parent_id?: Nullable<number>
  extractor_id?: Nullable<number>
  group_id?: Nullable<number>
  loader_id?: Nullable<number>
  task_id?: Nullable<number>
  template_id?: Nullable<number>
  transformer_id?: Nullable<number>
  element_type?: Nullable<string>
  element_guid?: Nullable<string>
  row_order?: Nullable<number>
  is_leaf?: Nullable<boolean>

  constructor ({ id, guid, name, parent_id, extractor_id, group_id, loader_id, task_id, template_id, transformer_id, element_type, element_guid, row_order, is_leaf }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.parent_id = parent_id
    this.extractor_id = extractor_id
    this.group_id = group_id
    this.loader_id = loader_id
    this.task_id = task_id
    this.template_id = template_id
    this.transformer_id = transformer_id
    this.element_type = element_type
    this.element_guid = element_guid
    this.row_order = row_order
    this.is_leaf = is_leaf
  }
}

export default class TreeElement extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private parentId: Nullable<number>
  private extractorId: Nullable<number>
  private groupId: Nullable<number>
  private loaderId: Nullable<number>
  private taskId: Nullable<number>
  private templateId: Nullable<number>
  private transformerId: Nullable<number>
  private elementType: string
  private elementGuid: string
  private rowOrder: number
  private isLeaf: boolean

  constructor (
    name: string,
    elementType: string,
    elementGuid: string,
    rowOrder: number,
    isLeaf: boolean,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    parentId?: Nullable<number>,
    extractorId?: Nullable<number>,
    groupId?: Nullable<number>,
    loaderId?: Nullable<number>,
    taskId?: Nullable<number>,
    templateId?: Nullable<number>,
    transformerId?: Nullable<number>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.parentId = parentId
    this.extractorId = extractorId
    this.groupId = groupId
    this.loaderId = loaderId
    this.taskId = taskId
    this.templateId = templateId
    this.transformerId = transformerId
    this.elementType = elementType
    this.elementGuid = elementGuid
    this.rowOrder = rowOrder
    this.isLeaf = isLeaf
  }

  static create (dto: TreeElementDTO): TreeElement {
    return new TreeElement(
      dto.name,
      dto.element_type,
      dto.element_guid,
      dto.row_order,
      dto.is_leaf,
      dto.id,
      dto.guid,
      dto.parent_id,
      dto.extractor_id,
      dto.group_id,
      dto.loader_id,
      dto.task_id,
      dto.template_id,
      dto.transformer_id
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId () {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setParentId (parentId: Nullable<number>): void {
    this.parentId = parentId
  }

  getParentId (): Nullable<number> {
    return this.parentId
  }

  setElementType (elementType: string): void {
    this.elementType = elementType
  }

  getElementType (): string {
    return this.elementType
  }

  setElementGuid (elementGuid: string): void {
    this.elementGuid = elementGuid
  }

  getElementGuid (): string {
    return this.elementGuid
  }

  setRowOrder (rowOrder: number): void {
    this.rowOrder = rowOrder
  }

  getRowOrder (): number {
    return this.rowOrder
  }

  setIsLeaf (isLeaf: boolean): void {
    this.isLeaf = isLeaf
  }

  getIsLeaf (): boolean {
    return this.isLeaf
  }
}
